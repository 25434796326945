@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;
@use 'iro-design/src/mixins' as mx;
@use 'include-media/dist/include-media' as media;

@include iro.props-namespace('reactions') {
    @include iro.props-store((
        --dims: (
            --pad: fn.foreign-dim(--action-button, --pad-y),
        )
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --bg: fn.global-color(--bg),
        ),
    ), 'colors');

    @include iro.bem-component(iro.props-namespace()) {
        display: flex;

        @include iro.bem-elem('reaction') {
            display:          block;
            position:         relative;
            padding:          fn.dim(--pad);
            border-radius:    100em;
            background-color: fn.color(--bg);
        }
    }
}
