@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('header') {
    @include iro.props-store((
        --dims: (
            --height: fn.global-dim(--size --700),
            --pad-x:  fn.global-dim(--size --250),

            --side: (
                --pad-x: fn.global-dim(--size --200),
            ),
        )
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --border: fn.global-color(--border --stable),
        ),
    ), 'colors');

    @include iro.bem-layout(iro.props-namespace()) {
        grid-area:        header;
        position:         sticky;
        z-index:          1000;
        top:              0;
        box-sizing:       border-box;
        min-width:        0;
        height:           fn.dim(--height);
        padding-right:    fn.dim(--pad-x);
        padding-left:     fn.dim(--pad-x);
        backdrop-filter:  blur(1em);
        border-bottom:    1px solid fn.color(--border);

        &::before {
            content:          '';
            display:          block;
            position:         absolute;
            z-index:          -10;
            top:              0;
            left:             0;
            width:            100%;
            height:           100%;
            opacity:          .85;
            background-color: fn.global-color(--bg);
        }

        @include iro.bem-modifier('right') {
            padding: 0 fn.dim(--side --pad-x);
        }
    }
}
