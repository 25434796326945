@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;
@use 'iro-design/src/mixins' as mx;
@use 'include-media/dist/include-media' as media;

@include iro.props-namespace('note') {
    @include iro.props-store((
        --dims: (
            --spacing-x: fn.global-dim(--size --300),
            --spacing-y: fn.global-dim(--size --150),

            --body: (
                --pad-y: fn.global-dim(--paragraph --margin-top),
            ),

            --attachments-grid: (
                --item-width: fn.global-dim(--size --2800),
            ),

            --compact: (
                --attachments-grid: (
                    --item-width: fn.global-dim(--size --2300),
                ),
            )
        )
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --border: fn.global-color(--border --regular),

            --collapse: (
                --idle:  fn.global-color(--fg-hi3),
                --hover: fn.global-color(--fg-hi)
            ),

            --key-focus: (
                --border: fn.global-color(--focus --fill),
            ),
        ),
    ), 'colors');

    @include iro.bem-component(iro.props-namespace()) {
        @include iro.bem-elem('header') {
            @include iro.bem-sibling-elem('header') {
                margin-top:  fn.dim(--spacing-y);
                padding-top: fn.dim(--spacing-y);
                border-top:  1px solid fn.color(--border);
            }
        }

        @include iro.bem-elem('menu-actions') {
            display: flex;
        }

        @include iro.bem-elem('embed') {
            margin-top: fn.dim(--spacing-y);
            border:     1px solid fn.color(--border);
        }

        @include iro.bem-elem('title') {
            display:    block;
            margin-top: fn.dim(--body --pad-y);
        }

        @include iro.bem-elem('body') {
            margin-bottom:  calc(-1 * fn.dim(--spacing-y));
            padding-top:    fn.dim(--body --pad-y);
            padding-bottom: fn.dim(--body --pad-y);
            font-size:      fn.global-dim(--font-size --150);
            word-wrap:      break-word;
            white-space:    pre-wrap;

            &::before {
                content:       '';
                display:       block;
                margin-top:    -100em;
                margin-bottom: 100em;
            }

            @include iro.bem-modifier('large') {
                font-size: fn.global-dim(--font-size --200);
            }
        }

        @include iro.bem-elem('collapsible') {
            @include iro.bem-is('collapsed') {
                overflow: clip;
    
                @include iro.bem-sibling-elem('collapse-indicator') {
                    display: block;
                }
            }
        }

        @include iro.bem-elem('collapse-indicator') {
            display:       none;
            position:      relative;
            z-index:       10;
            width:         100%;
            margin-top:    calc(-2 * fn.dim(--spacing-y));
            padding-top:   calc(3 * fn.dim(--spacing-y));
            border-bottom: 2px dashed fn.color(--collapse --idle);

            &:hover {
                border-bottom-color: fn.color(--collapse --hover);
            }
    
            @include iro.bem-at-theme('keyboard') {
                &:focus {
                    border-bottom-color: fn.color(--key-focus --border);
                }
            }
        }
        
        @include iro.bem-elem('footer') {
            display:     flex;
            flex-flow:   row wrap;
            align-items: center;
            margin-top:  fn.dim(--spacing-y);
            column-gap:  fn.dim(--spacing-y);
            border-top:  1px solid fn.color(--border);
        
            @include iro.bem-modifier('compact') {
                margin-top: 0;
                border-top: 0;
            }
        
            @include iro.bem-modifier('wrap-reverse') {
                flex-wrap: wrap-reverse;
            }
        }
        
        @include iro.bem-elem('footer-section') {
            flex:        0 1 auto;
            margin-top:  fn.dim(--spacing-y);
        
            @include iro.bem-modifier('gaps') {
                display:     flex;
                flex-flow:   row wrap;
                align-items: center;
                column-gap:  fn.dim(--spacing-x);
            }
        
            @include iro.bem-modifier('reactions') {
                display: flex;
            }
        
            @include iro.bem-next-twin-elem {
                margin-left: auto;
            }
        }

        @include iro.bem-elem('attachments') {
            display:        flex;
            flex-direction: column;
            gap:            fn.dim(--spacing-y);
            margin-top:     fn.dim(--spacing-y);

            @include iro.bem-modifier('grid') {
                display:               grid;
                grid-template-columns: repeat(auto-fit, minmax(fn.dim(--attachments-grid --item-width), 1fr));
                grid-auto-rows:        auto;
            }

            @include iro.bem-modifier('grid-3') {
                display:               grid;
                grid-template-columns: 2fr 1fr;
                grid-template-rows:    1fr 1fr;
                grid-template-areas:   'first second' 'first third';

                :first-child {
                    grid-area: first;
                }

                :nth-child(2) {
                    grid-area: second;
                }

                :nth-child(3) {
                    grid-area: third;
                }
            }

            @include iro.bem-modifier('compact') {
                grid-template-columns: repeat(auto-fit, minmax(fn.dim(--compact --attachments-grid --item-width), 1fr));
            }
        }

        @include media.media('<=md') {
            @include iro.bem-elem('interaction-btn-count') {
                display: none;
            }
        }
    }
}
