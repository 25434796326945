@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;
@use 'iro-design/src/mixins' as mx;
@use 'include-media/dist/include-media' as media;

@include iro.props-namespace('notification') {
    @include iro.props-store((
        --dims: (
            --spacing-x: fn.foreign-dim(--media, --pad-x),
            --spacing-y: fn.global-dim(--size --100),
        )
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --border: fn.global-color(--border --regular),
        ),
    ), 'colors');

    @include iro.bem-component(iro.props-namespace()) {
        display:               grid;
        align-items:           center;
        grid-template-columns: fn.foreign-dim(--emoji, --size) 1fr auto;
        grid-auto-rows:        auto;
        grid-template-areas:   'icon header date';
        border-radius:         0;
        gap:                   fn.dim(--spacing-y) fn.dim(--spacing-x);

        @include iro.bem-elem('icon') {
            grid-area:    icon;
            justify-self: center;
        }

        @include iro.bem-elem('header') {
            grid-area: header;
            min-width: 0%;
            font-size: fn.global-dim(--font-size --75);
        }

        @include iro.bem-elem('date') {
            grid-area: date;
            font-size: fn.global-dim(--font-size --75);
        }

        @include iro.bem-elem('content') {
            grid-area: 2 / 2 / span 1 / -1;
            min-width: 0%;
            opacity:   .75;
            font-size: fn.global-dim(--font-size --75);
        }

        @include iro.bem-elem('note') {
            display:       block;
            max-height:    8em;
            overflow:      hidden;
            text-overflow: ellipsis;
            white-space:   pre-wrap;
            mask-image:    linear-gradient(to bottom, #000 5em, transparent 8em);

            &::before {
                content:       '';
                display:       block;
                margin-top:    -100em;
                margin-bottom: 100em;
            }
        }
    }
}
