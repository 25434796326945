@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('context') {
    @include iro.props-store((
        --dims: (
            --spacing:  fn.global-dim(--size --150),
        ),
    ), 'dims');

    @include iro.bem-layout(iro.props-namespace()) {
        display:        flex;
        flex-direction: column;
        gap:            fn.dim(--spacing);
    }
}
