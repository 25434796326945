@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('mention') {
    @include iro.props-store((
        --dims: (
            --pad-x:    fn.global-dim(--size --40),
            --pad-y:    0,
            --rounding: 3px,
        )
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --fg: fn.global-color(--accent --primary --quiet --fg),
            --bg: fn.global-color(--accent --primary --quiet --bg),
        ),
    ), 'colors');

    @include iro.bem-object(iro.props-namespace()) {
        padding:          fn.dim(--pad-y) fn.dim(--pad-x);
        border-radius:    fn.dim(--rounding);
        background-color: fn.color(--bg);
        color:            fn.color(--fg);

        &:link,
        &:visited {
            border-radius:   fn.dim(--rounding) !important;
            color:           fn.color(--fg) !important;
            text-decoration: none !important;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}
