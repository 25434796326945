@use 'iro-design/src/functions' as fn;

html {
    scrollbar-gutter: stable;
}

body {
    display:               grid;
    grid-template-columns: auto minmax(0, 1fr) auto auto;
    grid-template-rows:    auto 1fr;
    grid-template-areas:
        'nav header side-handle side'
        'nav main   side-handle side';
    box-sizing:            border-box;
    height:                auto;
    min-height:            100%;
    margin:                0;
    padding:               0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color:       fn.color(--fg-lo);
    font-weight: 700;
}

pre,
code {
    font-size: fn.dim(--font-size --100);
}
