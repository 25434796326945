@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('sidebar') {
    @include iro.props-store((
        --dims: (
            --nav: (
                --pad-x: fn.global-dim(--size --125),
                --pad-y: fn.global-dim(--size --125),
            ),

            --widgets: (
                --min-width: 250px,
                --max-width: 30vw,
            )
        )
    ), 'dims');

    @include iro.bem-layout(iro.props-namespace()) {
        position:   sticky;
        top:        0;
        box-sizing: border-box;
        height:     100vh;
    
        @include iro.bem-modifier('nav') {
            grid-area: nav;
            padding:   fn.dim(--nav --pad-y) fn.dim(--nav --pad-x);
        }

        @include iro.bem-modifier('widgets') {
            min-width:       fn.dim(--widgets --min-width);
            max-width:       fn.dim(--widgets --max-width);
            grid-area:       side;
            overflow:        auto;
            scrollbar-color: fn.global-color(--fg-hi3) fn.global-color(--bg);
        }
    }
}
