@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('notifications') {
    @include iro.props-store((
        --dims: (
            --pad-x:   fn.global-dim(--size --200),
            --pad-y:   fn.global-dim(--size --200),
            --spacing: fn.global-dim(--size --200),
        )
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --border: fn.global-color(--border --regular),
        ),
    ), 'colors');

    @include iro.bem-layout(iro.props-namespace()) {
        padding: fn.dim(--pad-y) fn.dim(--pad-x);
        
        @include iro.bem-elem('notification') {
            @include iro.bem-next-twin-elem {
                margin-top:  fn.dim(--spacing);
                padding-top: fn.dim(--spacing);
                border-top:  1px solid fn.color(--border);
            }
        }
    }
}
