@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('main') {
    @include iro.props-store((
        --dims: (
            --pad: fn.global-dim(--size --250),
    
            --content: (
                --width: iro.fn-px-to-rem(700px),
                --pad-y: fn.global-dim(--size --250),
            )
        )
    ), 'dims');

    @include iro.bem-layout(iro.props-namespace()) {
        position:   relative;
        grid-area:  main;
        min-width:  0;
        min-height: 0;
        padding:    fn.dim(--pad);

        @include iro.bem-elem('content') {
            margin-top:    fn.dim(--content --pad-y);
            margin-bottom: fn.dim(--content --pad-y);

            @include iro.bem-modifier('narrow') {
                max-width:    fn.dim(--content --width);
                margin-right: auto;
                margin-left:  auto;
            }
        }

        @include iro.bem-elem('notice') {
            position: sticky;
            z-index:  1000;
            top:      calc(fn.dim(--pad) + fn.foreign-dim(--header, --height));
        }
        
        @include iro.bem-modifier('centered') {
            align-self: center;
        }
    }
}
