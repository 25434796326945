@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('replies') {
    @include iro.props-store((
        --dims: (
            --border:   fn.global-dim(--border --medium),
            --pad-left: fn.global-dim(--size --200),
            --spacing:  fn.global-dim(--size --150),
        ),
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --border: fn.global-color(--obj-hi),
        ),
    ), 'colors');

    @include iro.bem-layout(iro.props-namespace()) {
        display:        flex;
        flex-direction: column;
        gap:            fn.dim(--spacing);

        & & {
            padding-left:   fn.dim(--pad-left);
            border-left:    fn.dim(--border) solid fn.color(--border);
        }
    }
}
